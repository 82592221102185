/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { t } from '@superset-ui/core';
import { InfoTooltipWithTrigger } from './InfoTooltipWithTrigger';
import { Tooltip } from './Tooltip';
export default function ControlHeader({ name, description, label, tooltipOnClick, onClick, warning, danger, leftNode, rightNode, validationErrors = [], renderTrigger = false, hovered = false, required = false, }) {
    const renderOptionalIcons = () => {
        if (hovered) {
            return (React.createElement("span", null,
                description && (React.createElement("span", null,
                    React.createElement(InfoTooltipWithTrigger, { label: t('description'), tooltip: description, placement: "top", onClick: tooltipOnClick }),
                    ' ')),
                renderTrigger && (React.createElement("span", null,
                    React.createElement(InfoTooltipWithTrigger, { label: t('bolt'), tooltip: t('Changing this control takes effect instantly'), placement: "top", icon: "bolt" }),
                    ' '))));
        }
        return null;
    };
    if (!label) {
        return null;
    }
    const labelClass = validationErrors.length > 0 ? 'text-danger' : '';
    return (React.createElement("div", { className: "ControlHeader", "data-test": `${name}-header` },
        React.createElement("div", { className: "pull-left" },
            React.createElement("label", { className: "control-label", htmlFor: name },
                leftNode && React.createElement("span", null, leftNode),
                React.createElement("span", { role: "button", tabIndex: 0, onClick: onClick, className: labelClass, style: { cursor: onClick ? 'pointer' : '' } }, label),
                ' ',
                warning && (React.createElement("span", null,
                    React.createElement(Tooltip, { id: "error-tooltip", placement: "top", title: warning },
                        React.createElement("i", { className: "fa fa-exclamation-circle text-warning" })),
                    ' ')),
                danger && (React.createElement("span", null,
                    React.createElement(Tooltip, { id: "error-tooltip", placement: "top", title: danger },
                        React.createElement("i", { className: "fa fa-exclamation-circle text-danger" })),
                    ' ')),
                validationErrors.length > 0 && (React.createElement("span", null,
                    React.createElement(Tooltip, { id: "error-tooltip", placement: "top", title: validationErrors.join(' ') },
                        React.createElement("i", { className: "fa fa-exclamation-circle text-danger" })),
                    ' ')),
                renderOptionalIcons(),
                required && (React.createElement("span", { className: "text-danger m-l-4" },
                    React.createElement("strong", null, "*"))))),
        rightNode && React.createElement("div", { className: "pull-right" }, rightNode),
        React.createElement("div", { className: "clearfix" })));
}
